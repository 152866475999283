import React, { useState } from 'react'
import FromFantom from '../../sections/FromFantom/FromFantom'
import ToFantom from '../../sections/ToFantom/ToFantom'

import './style.scss'

export default function TradePage() {

  const [pageStep, setPageStep] = useState(1)

  return (
    <div className='trade-page'>
      {
        pageStep === 1 ? 
          <div className='page-container trade-buttons'>
            <div className='header'>Trade DABA</div>
            <button
              onClick={() => setPageStep(2)}
              className='to-fantom'
            >
              <img src="./image/monkey.png" alt="" />
              <img src="./image/dots.png" alt="" />
              <img src="./image/coins/ftm.png" alt="" />
              <div>IGD to OCD</div>
            </button>
            <button
              onClick={() => setPageStep(3)}
              className='from-fantom'
            >
              <img src="./image/coins/ftm.png" alt="" />
              <img src="./image/dots.png" alt="" />
              <img src="./image/monkey.png" alt="" />
              <div>OCD to IGD</div>
            </button>
          </div>
        : pageStep === 2 ?
          <ToFantom handleBack={() => setPageStep(1)} />
        :
          <FromFantom handleBack={() => setPageStep(1)} />
        
      }
    </div>
  )
}