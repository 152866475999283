/* global BigInt */

import Web3 from "web3"
import abi from '../data/erc20abi.json'
import axios from 'axios'

const tokenids = 'ethereum,tether,usd-coin,dai,shiba-inu,binancecoin,binance-usd,matic-network,fantom,dogecoin,avalanche-2'

export const tokenPrices = {
  ETH: 1,
  USDT: 1,
  USDC: 1,
  DAI: 1,
  SHIB: 1,
  BNB: 1,
  BUSD: 1,
  MATIC: 1,
  FTM: 1,
  DOGE: 1,
  AVAX: 1,
  LINK: 500
}

export const getTokenPrices = async () => {
  try {
    let res = await axios.get(
      'https://api.coingecko.com/api/v3/coins/markets',
      {
        params: {
          ids: tokenids,
          vs_currency: 'usd'
        }
      }
    )
    res.data.forEach(element => {
      tokenPrices[element.symbol.toUpperCase()] = element.current_price
    });
  } catch(err){
    console.log(err)
  }
}

export const getTokenBalance = async (accountAddress, tokenAddress, provider, decimals = 0) => {
  if(!accountAddress) return 0;
  let balance

  const web3 = new Web3(provider)

  if(tokenAddress === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
    balance = parseInt(await web3.eth.getBalance(accountAddress))
  }
  else {
    let contract = new web3.eth.Contract(abi, tokenAddress)
    balance = parseInt(await contract.methods.balanceOf(accountAddress).call())
  }

  balance /= Math.pow(10, decimals)

  return balance;
}

export const sendValue = async (from, to, value, provider, tokenAddress, decimals, chainId) => {
  const web3 = new Web3(provider)
  let v = BigInt(parseInt(value * 1000000)) * BigInt(Math.pow(10, decimals - 6))

  let gasPrice

  if(chainId === 250) {
    gasPrice = '20000000000'
  }

  if(tokenAddress === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
    try{
      let res = await web3.eth.sendTransaction({
        from: from,
        to: to,
        value: v.toString(),
        gasPrice
      })
      return res
    } catch(err) {
      console.log(err)
      return ''
    }
  }
  else {
    try {
      let contract = new web3.eth.Contract(abi, tokenAddress)
      let res = await contract.methods.transfer(to, v).send({
        from: from,
        gasPrice
      })
      return res
    } catch (error) {
      console.log(error)
      return ''
    }
  }
}