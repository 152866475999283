const applicationId = "sandbox-sq0idb-Ub0brfDpdKzJGT6BaLKb4w";
const locationId = "LD23J0XGDM620";

let payments;

export const init = () => {
  payments = window.Square.payments(applicationId, locationId);
};

const createPayment = async (token, amount) => {
  const dataJsonString = JSON.stringify({
    token,
    amount
  });

  try {
    const response = await fetch("process-payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: dataJsonString,
    });

    const data = await response.json();

    if (data.errors && data.errors.length > 0) {
      if (data.errors[0].detail) {
        window.showError(data.errors[0].detail);
      } else {
        window.showError("Payment Failed.");
      }
    } else {
      window.showSuccess("Payment Successful!");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const getPaymentRequest = (amount) => {
  return {
    requestShippingAddress: false,
    requestBillingInfo: true,
    currencyCode: "USD",
    countryCode: "US",
    total: {
      label: "DABA",
      amount: amount + '',
      pending: false
    },
    lineItems: [
      {
        label: "Subtotal",
        amount: amount + '',
        pending: false
      }
    ]
  };
}

export async function GooglePay(amount) {
  const paymentRequest = payments.paymentRequest(
    // Use global method from sq-payment-flow.js
    getPaymentRequest(amount)
  );
  const googlePay = await payments.googlePay(paymentRequest);
  //await googlePay.attach(buttonEl);

  try {
    const result = await googlePay.tokenize();
    if (result.status === "OK") {
      // Use global method from sq-payment-flow.js
      createPayment(result.token, amount);
    }
  } catch (e) {
    if (e.message) {
      console.log(`Error: ${e.message}`);
    } else {
      console.log("Something went wrong");
    }
  }
}

export let payViaCard

export async function CardPay(fieldEl) {
  // Create a card payment object and attach to page
  const card = await payments.card({
    style: {
      '.input-container.is-focus': {
        borderColor: '#006AFF'
      },
      '.message-text.is-error': {
        color: '#BF0020'
      }
    }
  });
  await card.attach(fieldEl);

  payViaCard = async (amount) => {
    // Clear any existing messages

    try {
      const result = await card.tokenize();
      if (result.status === 'OK') {
        // Use global method from sq-payment-flow.js
        createPayment(result.token, amount);
      }
    } catch (e) {
      if (e.message) {
        console.log(`Error: ${e.message}`);
      } else {
        console.log('Something went wrong');
      }
    }
  }
}