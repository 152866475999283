import React from "react";
import ReactModal from "react-modal";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useAlert } from 'react-alert';

import './style.scss'

const paypalModalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(70, 70, 70, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    border: "none",
    background: "#132209",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    outline: "none",
    padding: "20px 14px 20px 20px",
    width: "96%",
    maxWidth: "400px",
    borderRadius: "18px",
    inset: "0",
  },
};

export default function PaypalModal({ isOpen, onRequestClose, amount, onSuccess }) {

  const alert = useAlert()

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={paypalModalStyle}
      ariaHideApp={false}
    >
      <div className="paypal-modal">
        <div className="modal-title">
          Checkout ${amount} via PayPal
          <button className="modal-close-button" onClick={onRequestClose}>
            <img src="./image/close.png" alt="" />
          </button>
        </div>
        <div className="line"></div>
        <div className="paypal-container">
          <PayPalScriptProvider
            options={{
              "client-id": "AbJJbOPvkEh4aiSCln1E33sldankp_Yj715eskigRGC_KUE6twQtjh14yACx4QYHdlKBXvyKc28RRMG1",
              currency: "USD",
            }}
          >
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: amount,
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  const name = details.payer.name.given_name;
                  onSuccess()
                  alert.success(`Transaction completed by ${name}`)
                });
              }}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </ReactModal>
  );
}
