import React, { useEffect, useRef, useState } from "react";
import ATListModal from "../../components/ATListModal/ATListModal";
import { getValues, setData } from './../../utils/firebase';
import { useEthers } from '@usedapp/core';
import { useAlert } from 'react-alert';

import "./style.scss";

import atList from '../../data/at_list.json'

export default function SwapArcadePage() {

  const batValueRef = useRef()
  const selectedAtValueRef = useRef()

  const [accountBalance, setAccountBalance] = useState({DB: 0, AT: 0, BOUNTY: 0})
  const [selectedAt, setSelectedAt] = useState(0)
  const [swapDirection, setSwapDirection] = useState(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [batValue, setBatValue] = useState(0)
  const [selectedAtValue, setSelectedAtValue] = useState(0)
  const [available, setAvailable] = useState('Insufficient Balance')

  const {account} = useEthers()
  const alert = useAlert()

  const handleBatValueChange = (e) => {
    batValueRef.current.value = parseInt(batValueRef.current.value)
    setBatValue(batValueRef.current.value)

    if(swapDirection === 1) {
      selectedAtValueRef.current.value = batValueRef.current.value * atList[selectedAt].buyRatio
    }
    else {
      selectedAtValueRef.current.value = batValueRef.current.value * atList[selectedAt].sellRatio
    }

    setSelectedAtValue(selectedAtValueRef.current.value)
  }

  const handleSelectedAtValueChange = e => {
    selectedAtValueRef.current.value = parseInt(selectedAtValueRef.current.value)
    setSelectedAtValue(selectedAtValueRef.current.value)
    
    if(swapDirection === 1) {
      batValueRef.current.value = parseInt(selectedAtValueRef.current.value / atList[selectedAt].buyRatio)
    }
    else {
      batValueRef.current.value = parseInt(selectedAtValueRef.current.value / atList[selectedAt].sellRatio)
    }

    setBatValue(batValueRef.current.value)
  }

  const handleSwapDirection = () => {
    setSwapDirection(prev => 4 - prev)
    if(swapDirection === 1) {
      selectedAtValueRef.current.value = atList[selectedAt].sellRatio * batValueRef.current.value
    }
    else {
      selectedAtValueRef.current.value = atList[selectedAt].buyRatio * batValueRef.current.value
    }
    setSelectedAtValue(selectedAtValueRef.current.value)
  }

  const loadBalance = async () => {
    let v = await getValues(account)
    setAccountBalance(v)
    batValueRef.current.value = '1'
    if(v.DB > 0) {
      if(swapDirection === 1)
        selectedAtValueRef.current.value = atList[selectedAt].buyRatio
      else
        selectedAtValueRef.current.value = atList[selectedAt].sellRatio
    }
    setBatValue(1)
    setSelectedAtValue(selectedAtValueRef.current.value)
  }

  const handleSwap = async () => {
    if(batValue === 0 || batValue === '') {
      alert.info('Input Balance')
      return
    }
    let v = accountBalance
    let selSymbol = atList[selectedAt].symbol
    if(swapDirection === 1) {
      if(v[selSymbol] === undefined) {
        v[selSymbol] = parseInt(selectedAtValue)
      }
      else {
        v[selSymbol] = v[selSymbol] + parseInt(selectedAtValue)
      }
      v.AT -= parseInt(batValue)
    }
    else {
      v[selSymbol] -= parseInt(selectedAtValue)
      v.AT += parseInt(batValue)
    }
    await setData(account, v)
    loadBalance()
    alert.success('Success')
  }

  useEffect(() => {
    if(account) {
      loadBalance()
    }
    else {
      setAccountBalance({DB: 0, AT: 0, BOUNTY: 0})
    }
  }, [account]) //eslint-disable-line

  useEffect(() => {
    if(swapDirection === 1) {
      selectedAtValueRef.current.value = atList[selectedAt].buyRatio * batValueRef.current.value
      setSelectedAtValue(selectedAtValueRef.current.value)
    }
    else {
      selectedAtValueRef.current.value = 0
      batValueRef.current.value = 0
      setSelectedAtValue(0)
      setBatValue(0)
    }
  }, [selectedAt]) //eslint-disable-line

  useEffect(() => {
    if(account === undefined) {
      setAvailable('Connect Your Wallet')
    }
    else if(swapDirection === 1) {
      if(accountBalance.AT < batValue) setAvailable('Insufficient Balance')
      else setAvailable('')
    }
    else {
      if(accountBalance[atList[selectedAt].symbol] === undefined)
        setAvailable('Insufficient Balance')
      else if(accountBalance[atList[selectedAt].symbol] < selectedAtValue)
        setAvailable('Insufficient Balance')
      else
        setAvailable('')
    }
  }, [batValue, selectedAtValue, swapDirection, accountBalance]) //eslint-disable-line

  return (
    <div className="swap-arcade-page">
      <div className="page-container">
        <div className="header">Swap Arcade</div>

        <div className="select-container" style={{order: swapDirection}}>
          <div className="select-container-body">
            <div className="input-container">
              <input
                type="number"
                className="value"
                ref={batValueRef}
                onChange={handleBatValueChange}
                placeholder="Input Amount"
              />
            </div>
            <button className="list-button" onClick={() => {}}>
              <img src="./image/arcade-token.png" alt="BAT" />
              BAT
            </button>
          </div>
          <div className="select-container-footer">
            <div className="footer-left"></div>
            <div className="footer-right">Balance: {accountBalance.AT}</div>
          </div>
        </div>

        <div className="swap-btn-container" onClick={handleSwapDirection}>
          <button>
            <img src="./image/swap.png" alt="swap" />
          </button>
        </div>
        

        <div className="select-container" style={{order: 4-swapDirection}}>
          <div className="select-container-body">
            <div className="input-container">
              <input
                type="number"
                className="value"
                ref={selectedAtValueRef}
                onChange={handleSelectedAtValueChange}
                placeholder="Input Amount"
              />
            </div>
            <button className="list-button" onClick={() => setIsModalOpen(true)}>
              <img src={atList[selectedAt].image} alt={atList[selectedAt].symbol} />
              {atList[selectedAt].symbol}
            </button>
          </div>
          <div className="select-container-footer">
            <div className="footer-left"></div>
            <div className="footer-right">
              Balance: {accountBalance[atList[selectedAt].symbol] ? accountBalance[atList[selectedAt].symbol] : 0}
            </div>
          </div>
        </div>

      </div>

      <button
        disabled={available === '' ? '' : 'disabled'}
        onClick={handleSwap}
      >
        {available === '' ? 'S W A P' : available}
      </button>

      <ATListModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        selectedAt={selectedAt}
        handleChange={setSelectedAt}
      />
      
    </div>
  );
}
