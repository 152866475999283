import React from "react";
import ReactModal from "react-modal";
import WalletConnectProvider from '@walletconnect/web3-provider'

import './style.scss'
import { useEthers } from '@usedapp/core';

const walletModalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(70, 70, 70, 0.6)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    position: "relative",
    border: "none",
    background: "#132209",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    outline: "none",
    padding: "20px",
    width: "96%",
    maxWidth: "400px",
    borderRadius: "18px",
    inset: "0",
  },
};

export default function WalletModal({ isOpen, onRequestClose}) {

  const { activateBrowserWallet, activate } = useEthers()

  const connectMetamask = async () => {
    onRequestClose()
    await activateBrowserWallet()
  }

  const connectWalletconnect = async () => {
    onRequestClose()
    try {
      const provider = new WalletConnectProvider({
        infuraId: '62687d1a985d4508b2b7a24827551934',
      })
      await provider.enable()
      await activate(provider)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={walletModalStyle}
      ariaHideApp={false}
    >
      <div className="wallet-modal">
        <div className="modal-title">
          Connect Wallet
          <button className='modal-close-button' onClick={onRequestClose}>
            <img src="./image/close.png" alt="" />
          </button>
        </div>
        <div className="line"></div>
        <div className="wallet-button" onClick={connectMetamask}>
          <img src="./image/wallet/metamask.svg" alt="metamask" />
          <div className="title">Metamask</div>
        </div>
        <div className="wallet-button" onClick={connectWalletconnect}>
          <img src="./image/wallet/walletconnect.png" alt="walletconnect" />
          <div className="title">Wallet Connect</div>
        </div>
      </div>
    </ReactModal>
  );
}
