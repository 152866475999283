import React from 'react'
import ReactModal from 'react-modal'

import atList from '../../data/at_list.json'
import './style.scss'

const atListModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(70, 70, 70, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    position: 'relative',
    border: 'none',
    background: '#132209',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '20px',
    width: '340px',
    borderRadius: '18px',
    inset: '0'
  }
}

export default function ATListModal({ isOpen, onRequestClose, selectedAt, handleChange }) {

  const handleClick = ind => {
    if(ind === selectedAt) return
    handleChange(ind)
    onRequestClose()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={atListModalStyle}
      ariaHideApp={false}
    >
      <div className='atlist-modal'>
        <div className='modal-title'>
          Select Arcade Token
          <button className='modal-close-button' onClick={onRequestClose}>
            <img src="./image/close.png" alt="" />
          </button>
        </div>
        <div className='line'></div>
        <ul className='coin-list'>
          {
            atList.map((val, ind) => (
              <li
                key={ind}
                className={ind === selectedAt ? 'active' : ''}
                onClick={() => handleClick(ind)}
              >
                <img src={val.image} alt="key" />
                <div>{val.name} ({val.symbol})</div>
              </li>
            ))
          }
        </ul>
      </div>
    </ReactModal>
  )
}
