import React from 'react'

import './style.scss'
import { useState } from 'react';
import BuyPage from './../BuyPage/BuyPage';
import TradePage from './../TradePage/TradePage';
import SwapArcadePage from '../SwapArcadePage/SwapArcadePage';
import BuyBounty from '../BuyBounty/BuyBounty'
import BuyBountyPlayer from '../BuyBountyPlayer/BuyBountyPlayer'

export default function MainPage() {
  const [pageNumber, setPageNumber] = useState(1)

  const menuButtons = [
    'Buy Arcade',
    'Trade DABA',
    'Swap Arcade',
    'Buy Bounty Coin',
    'Buy Bounty Player'
  ]

  const pages = [
    <BuyPage />,
    <TradePage />,
    <SwapArcadePage />,
    <BuyBounty />,
    <BuyBountyPlayer />
  ]

  const handlePageButtonClicked = (num) => {
    if(pageNumber === num) return
    else {
      setPageNumber(num)
    }
  }

  return (
    <div className='main-page'>
      <div className='page-button-group'>
        {menuButtons.map((text, index) => (
          <button
            className={index + 1 === pageNumber ? 'active' : ''}
            onClick={() => handlePageButtonClicked(index + 1)}
            key={index}
          >{text}</button>
        ))}
      </div>
      {
        pages[pageNumber - 1]
      }
    </div>
  )
}
