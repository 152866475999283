import React, { useEffect, useState } from 'react'
import { useEthers } from '@usedapp/core';
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";

import CoinModal from './../../components/CoinModal/CoinModal';
import PackageModal from './../../components/PackageModal/PackageModal';
import { tokenPrices, getTokenBalance, sendValue } from './../../utils/utils';
import { getValues, setData } from './../../utils/firebase';
import coins from '../../data/coins.json'
import packages from '../../data/packages.json'

import './style.scss'
import { useAlert } from 'react-alert';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const spinnerStyle = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function ViaCrypto({ handleBack }) {

  const { chainId, account, active, library, activateBrowserWallet } = useEthers()
  const alert = useAlert()

  const [isOpenCoinModal, setIsOpenCoinModal] = useState(false)
  const [isOpenPackageModal, setIsOpenPackageModal] = useState(false)
  const [selectedCoin, setSelectedCoin] = useState(Object.keys(coins[chainId])[0])
  const [selectedPackage, setSelectedPackage] = useState(0)
  const [proceedLoading, setProceedLoading] = useState(false)
  const [balance, setBalance] = useState(0)
  const [fireBalance, setFireBalance] = useState({AT: 0, DB: 0, BOUNTY: 0})

  const handleProceed = async () => {
    setProceedLoading(true)
    try {
      const res = await sendValue(
        account,
        process.env.REACT_APP_GAME_WALLET_ADDRESS,
        packages[selectedPackage].amount / tokenPrices[selectedCoin],
        library.provider,
        coins[chainId][selectedCoin].address,
        coins[chainId][selectedCoin].decimals,
        chainId
      )
      if(res !== '') {
        let tmp = {
          ...fireBalance,
          AT: fireBalance.AT + packages[selectedPackage].total
        }

        setData(account, tmp)
        setFireBalance(tmp)
        loadCryptoBalance()
        alert.success('Success')
      }
      else {
        alert.error('Failed')
      }
    } catch (error) {
      alert.error('Failed')
      console.log(error)
    }

    setProceedLoading(false)
  }

  const loadBalanceFromFirebase = async () => {
    setFireBalance(await getValues(account))
  }

  const loadCryptoBalance = async () => {
    try {
      if(!account) {
        await activateBrowserWallet()
      }
      if(chainId && active && account && coins[chainId][selectedCoin]) {
        setBalance(await getTokenBalance(
          account,
          coins[chainId][selectedCoin].address,
          library.provider,
          coins[chainId][selectedCoin].decimals
        ))
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(chainId && active && account) {
      setSelectedCoin(Object.keys(coins[chainId])[0])
    }
  }, [chainId]) //eslint-disable-line

  useEffect(() => {

    if(account) {
      loadBalanceFromFirebase()
    }
    
    loadCryptoBalance()
  }, [chainId, selectedCoin, account]) //eslint-disable-line

  return (
    <div className='via-crypto'>
      <div className='page-container'>
        <div className='header'>
          <span onClick={handleBack}>Buy Arcade</span>
          Via Crypto
        </div>
        <div className='select-container'>
          <div className='select-container-body'>
            <div className='value'>{
              tokenPrices[selectedCoin] < 1.01 && tokenPrices[selectedCoin] > 0.95 ?
                packages[selectedPackage].amount
              : tokenPrices[selectedCoin] <=0.95 ?
                parseInt(packages[selectedPackage].amount / tokenPrices[selectedCoin])
              :
                (packages[selectedPackage].amount / tokenPrices[selectedCoin])
                .toFixed(parseInt(Math.log(tokenPrices[selectedCoin]) / Math.log(10)) + 2)
            }</div>
            <button
              className='list-button'
              onClick={() => setIsOpenCoinModal(true)}
            >
              <img
                src={coins[chainId][selectedCoin]?.image}
                alt={selectedCoin}
              />
              {selectedCoin}
              <div className='coin-price'>({tokenPrices[selectedCoin]})</div>
              <img src="./image/arrow-down.png" alt="arrow" />
            </button>
            <CoinModal
              isOpen={isOpenCoinModal}
              onRequestClose={() => setIsOpenCoinModal(false)}
              selectedCoin={selectedCoin}
              handleChange={setSelectedCoin}
              chainId={chainId}
            />
          </div>
          <div className='select-container-footer'>
            <div className='footer-left'></div>
            <div className='footer-right'>
              Balance: {balance}
            </div>
          </div>
        </div>

        <div className='select-container'>
          <div className='select-container-body'>
            <div className='value'>{packages[selectedPackage].total}</div>
            <button
              className='list-button'
              onClick={() => setIsOpenPackageModal(true)}
            >
              <img src="./image/arcade-token.png" alt="arcade" />
              Packages
              <img src="./image/arrow-down.png" alt="arrow" />
            </button>
            <PackageModal
              isOpen={isOpenPackageModal}
              onRequestClose={() => setIsOpenPackageModal(false)}
              selectedPackage={selectedPackage}
              handleChange={setSelectedPackage}
            />
          </div>
          
          <div className='select-container-footer'>
            <div className='footer-left'>= $ {packages[selectedPackage].amount}</div>
            <div className='footer-right'>
              Balance: {fireBalance.AT}
            </div>
          </div>
        </div>
      </div>

      <button
        onClick={handleProceed}
        disabled={account && packages[selectedPackage].amount / tokenPrices[selectedCoin] < balance && !proceedLoading ? '' : 'disable'}
      >
        {
          proceedLoading ? 
            <PulseLoader color="#bbb" loading={true} size={6} css={spinnerStyle} margin={7}/>
          : !account ?
            'Connect Wallet'
          : packages[selectedPackage].amount / tokenPrices[selectedCoin] > balance ?
            'Insufficient Balance'
          :
            'Proceed'
        }
      </button>
    </div>
  )
}
