const networks = {
  1: {
    name: 'Ethereum',
    chainId: '0x01',
    chainIdInt: 1,
    chainName: 'Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/c1ba29d27c6b40779d9a00a8850d4f9e'],
    blockExplorerUrls: ['https://etherscan.io'],
    apiEndpoint: 'https://api.etherscan.io/',
    apiKey: 'GWQRABHPZR8K96ARKSQWMT99PUV6ZGXV2Y',
    image: './image/coins/eth.png'
  },
  56: {
    name: 'Binance',
    chainId: '0x38',
    chainIdInt: 56,
    chainName: 'Bianance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],

    explorer: 'https://bscscan.com',
    apiKey: 'GZI4KWQJJ92TAZJDNXP25DBPVXV8XU4YYM',
    image: './image/coins/bnb.png'
  },
  137: {
    name: 'Polygon',
    chainId: '0x89',
    chainIdInt: 137,
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    
    apiEndpoint: 'https://api.polygonscan.com/',
    apiKey: 'TKMICNM95MU7T7DWN6YG98MGCQKJF358YU',
    image: './image/coins/polygon.svg'
  },
  250: {
    name: 'Fantom',
    chainId: '0x0FA',
    chainIdInt: 250,
    chainName: 'Fantom Opera',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18
    },
    rpcUrls: ['https://rpcapi.fantom.network'],
    blockExplorerUrls: ['https://ftmscan.com/'],
    image: './image/coins/ftm.png'
  },
  43114: {
    name: 'Avalanche',
    chainId: '0xa86a',
    chainIdInt: 43114,
    chainName: 'Avalanche',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io'],
    image: './image/coins/avax.svg'
  }
}


export default networks