import React from 'react'
import ReactModal from 'react-modal'

import coins from '../../data/coins.json'

import './style.scss'

const coinModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(70, 70, 70, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    position: 'relative',
    border: 'none',
    background: '#132209',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '20px',
    width: '300px',
    borderRadius: '18px',
    inset: '0'
  }
}

export default function CoinModal({ isOpen, onRequestClose, selectedCoin, handleChange, chainId }) {
  
  const handleClick = coin => {
    if(coin === selectedCoin) return
    handleChange(coin)
    onRequestClose()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={coinModalStyle}
      ariaHideApp={false}
    >
      <div className='coinlist-modal'>
        <div className='modal-title'>
          Select Token
          <button className='modal-close-button' onClick={onRequestClose}>
            <img src="./image/close.png" alt="" />
          </button>
        </div>
        <div className='line'></div>
        <ul className='coin-list'>
          {
            Object.keys(coins[chainId]).map(key => (
              <li
                key={key}
                className={key === selectedCoin ? 'active' : ''}
                onClick={() => handleClick(key)}
              >
                <img src={coins[chainId][key].image} alt="key" />
                <div>{key}</div>
              </li>
            ))
          }
        </ul>
      </div>
    </ReactModal>
  )
}