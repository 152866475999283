import "./style.scss";

import React, { useEffect, useRef, useState } from "react";
import { useEthers } from "@usedapp/core";
import { useAlert } from "react-alert";
import CoinModal from "../../components/CoinModal/CoinModal";
import { tokenPrices, getTokenBalance, sendValue } from "../../utils/utils";
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";
import { getValues, setData } from "../../utils/firebase";

import coins from "../../data/coins.json";
import BuyBountyViaFiat from "../../sections/BuyBountyViaFiat/BuyBountyViaFiat";

const bountyCoinPrice = 5;

const spinnerStyle = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function BuyBounty() {
  const [pageStep, setPageStep] = useState(1);
  const [selectedCoin, setSelectedCoin] = useState(0);
  const [isOpenCoinModal, setIsOpenCoinModal] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [bountyBalance, setBountyBalance] = useState(1);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [accountBalance, setAccountBalance] = useState({
    DB: 0,
    AT: 0,
    BOUNTY: 0,
  });

  const valueRef = useRef();

  const { chainId, account, active, library, activateBrowserWallet } =
    useEthers();
  const alert = useAlert();

  const handleProceed = async () => {
    setProceedLoading(true);
    try {
      const res = await sendValue(
        account,
        process.env.REACT_APP_GAME_WALLET_ADDRESS,
        (bountyBalance * bountyCoinPrice) / tokenPrices[selectedCoin],
        library.provider,
        coins[chainId][selectedCoin].address,
        coins[chainId][selectedCoin].decimals,
        chainId
      );
      if (res !== "") {
        handleSuccess()
      } else {
        alert.error("Failed");
      }
    } catch (error) {
      alert.error("Failed");
      console.log(error);
    }
    setProceedLoading(false);
  };

  const handleSuccess = () => {
    let tmp = { ...accountBalance };
    if (tmp.BOUNTY) {
      tmp.BOUNTY += parseInt(bountyBalance);
    } else {
      tmp.BOUNTY = parseInt(bountyBalance);
    }

    setData(account, tmp);
    setAccountBalance(tmp);
    loadCryptoBalance();
    alert.success("Success");
  };

  const handleChange = () => {
    setBountyBalance(valueRef.current.value);
  };

  const toViaCryptoPage = () => {
    if (chainId && active) setPageStep(2);
    else alert.error("Connect Wallet");
  };

  const toViaFiatPage = () => {
    setPageStep(3);
  };

  const loadBalance = async () => {
    let v = await getValues(account);
    setAccountBalance(v);
  };

  const loadCryptoBalance = async () => {
    try {
      if (!account) {
        await activateBrowserWallet();
      }
      if (chainId && active && account && coins[chainId][selectedCoin]) {
        setTokenBalance(
          await getTokenBalance(
            account,
            coins[chainId][selectedCoin].address,
            library.provider,
            coins[chainId][selectedCoin].decimals
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chainId && active && account) {
      setSelectedCoin(Object.keys(coins[chainId])[0]);
    }
  }, [chainId]); //eslint-disable-line

  useEffect(() => {
    loadCryptoBalance();
  }, [chainId, selectedCoin, account]); //eslint-disable-line

  useEffect(() => {
    if (account) {
      loadBalance();
    } else {
      setAccountBalance({ DB: 0, AT: 0, BOUNTY: 0 });
    }
  }, [account]); //eslint-disable-line

  return (
    <div className="buy-bounty">
      {pageStep === 1 && (
        <div className="page-container via-buttons">
          <div className="header">Buy Bounty Coin</div>
          <button className="button-crypto" onClick={toViaCryptoPage}>
            <img src="./image/coins/btc.png" alt="btc" />
            <div>Via Crypto</div>
          </button>
          <button className="button-fiat" onClick={toViaFiatPage}>
            <img src="./image/coins/fiat.png" alt="fiat" />
            <div>Via Fiat</div>
          </button>
        </div>
      )}
      {pageStep === 2 && (
        <div className="via-crypto">
          <div className="page-container">
            <div className="header">
              <span onClick={() => setPageStep(1)}>Buy Bounty Coin</span>
              Via Crypto
            </div>

            <div className="select-container">
              <div className="select-container-body">
                <div className="value">
                  {tokenPrices[selectedCoin] < 1.01 &&
                  tokenPrices[selectedCoin] > 0.95
                    ? bountyCoinPrice * bountyBalance
                    : tokenPrices[selectedCoin] <= 0.95
                    ? parseInt(
                        (bountyCoinPrice * bountyBalance) /
                          tokenPrices[selectedCoin]
                      )
                    : (
                        (bountyCoinPrice * bountyBalance) /
                        tokenPrices[selectedCoin]
                      ).toFixed(
                        parseInt(
                          Math.log(tokenPrices[selectedCoin]) / Math.log(10)
                        ) + 2
                      )}
                </div>
                <button
                  className="list-button"
                  onClick={() => setIsOpenCoinModal(true)}
                >
                  <img
                    src={coins[chainId][selectedCoin]?.image}
                    alt={selectedCoin}
                  />
                  {selectedCoin}
                  <div className="coin-price">
                    ({tokenPrices[selectedCoin]})
                  </div>
                  <img src="./image/arrow-down.png" alt="arrow" />
                </button>
                <CoinModal
                  isOpen={isOpenCoinModal}
                  onRequestClose={() => setIsOpenCoinModal(false)}
                  selectedCoin={selectedCoin}
                  handleChange={setSelectedCoin}
                  chainId={chainId}
                />
              </div>
              <div className="select-container-footer">
                <div className="footer-left"></div>
                <div className="footer-right">Balance: {tokenBalance}</div>
              </div>
            </div>

            <div className="select-container">
              <div className="select-container-body">
                <div className="input-container">
                  <input
                    type="number"
                    className="value"
                    ref={valueRef}
                    onChange={handleChange}
                    placeholder="Input Amount"
                  />
                </div>
                <button className="list-button" onClick={() => {}}>
                  <img src="./image/bounty-coin.png" alt="money coin" />
                  BOUNTY
                </button>
              </div>
              <div className="select-container-footer">
                <div className="footer-left">= ${bountyBalance * bountyCoinPrice}</div>
                <div className="footer-right">Balance: {accountBalance.BOUNTY ? accountBalance.BOUNTY : 0}</div>
              </div>
            </div>
          </div>

          <button
            onClick={handleProceed}
            disabled={
              account &&
              (bountyCoinPrice * bountyBalance) / tokenPrices[selectedCoin] <
                tokenBalance &&
              !proceedLoading
                ? ""
                : "disable"
            }
          >
            {proceedLoading ? (
              <PulseLoader
                color="#bbb"
                loading={true}
                size={6}
                css={spinnerStyle}
                margin={7}
              />
            ) : !account ? (
              "Connect Wallet"
            ) : (bountyCoinPrice * bountyBalance) / tokenPrices[selectedCoin] >
              tokenBalance ? (
              "Insufficient Balance"
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      )}
      {pageStep === 3 && (
        <BuyBountyViaFiat handleBack={() => setPageStep(1)} />
      )}
    </div>
  );
}
