/* global BigInt */

import Web3 from "web3";
import abi from "../data/daba_abi.json";

const web3 = new Web3(process.env.REACT_APP_FANTOM_RPC_URl);

const contract = new web3.eth.Contract(
  abi,
  process.env.REACT_APP_DABA_TOKEN_ADDRESS
);

export const sendDaba = (to, amount) =>
  new Promise(async (resolve, reject) => {
    try {
      const data = contract.methods
        .mint(to, web3.utils.toWei("" + amount))
        .encodeABI();

      const transaction = {
        from: process.env.REACT_APP_DABA_WALLET_ADDRESS,
        to: process.env.REACT_APP_DABA_TOKEN_ADDRESS,
        value: "0",
        gas: "150000",
        data: data,
      };

      const signedTx = await web3.eth.accounts.signTransaction(
        transaction,
        process.env.REACT_APP_DABA_WALLET_PRIVATE_KEY
      );

      await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

      resolve("success");
    } catch (error) {
      resolve("error");
    }
  });

export const retrieveDaba = (from, amount, provider) =>
  new Promise(async (resolve, reject) => {
    try {
      const w3 = new Web3(provider);
      const ctrt = new w3.eth.Contract(
        abi,
        process.env.REACT_APP_DABA_TOKEN_ADDRESS
      );
      const data = ctrt.methods
        .burn(
          w3.utils.toWei(amount + "")
        )
        .encodeABI()

      const transaction = {
        from: from,
        to: process.env.REACT_APP_DABA_TOKEN_ADDRESS,
        value: "0",
        gas: "15000",
        data: data,
        gasPrice: '20000000000'
      };

      const gas = await w3.eth.estimateGas(transaction)

      await w3.eth.sendTransaction({...transaction, gas})

      resolve("success");
    } catch (error) {
      resolve("error");
    }
  });

export const getBalance = async (account) => {
  let balance = await contract.methods.balanceOf(account).call();
  balance = BigInt(balance) / BigInt(Math.pow(10, 18));
  return parseInt(balance.toString());
};
