import React from 'react'
import { useState } from 'react';
import { useEthers } from '@usedapp/core';
import { useAlert } from 'react-alert';

import ViaCrypto from '../../sections/ViaCrypto/ViaCrypto';
import ViaFiat from './../../sections/ViaFiat/ViaFiat';
import './style.scss'

export default function BuyPage() {

  const [pageStep, setPageStep] = useState(1)

  const {chainId, active} = useEthers()

  const alert = useAlert()

  const toViaCryptoPage = () => {
    if(chainId && active) setPageStep(2)
    
    else alert.error('Connect Wallet')
  }

  const toViaFiatPage = () => {
    setPageStep(3)
  }

  return (
    <div className='buy-page'>
      {
        pageStep === 1 ?
          <div className='page-container via-buttons'>
            <div className='header'>Buy Arcade</div>
            <button
              className='button-crypto'
              onClick={toViaCryptoPage}
            >
              <img src="./image/coins/btc.png" alt="btc" />
              <div>Via Crypto</div>
            </button>
            <button
              className='button-fiat'
              onClick={toViaFiatPage}
            >
              <img src="./image/coins/fiat.png" alt="fiat" />
              <div>Via Fiat</div>
            </button>
          </div>
        : pageStep === 2 ?
          <ViaCrypto handleBack = {() => setPageStep(1)} />
        :
          <ViaFiat handleBack = {() => setPageStep(1)} />
      }
    </div>
  )
}
