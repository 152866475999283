import React from 'react'
import ReactModal from 'react-modal'

import packages from '../../data/packages.json'
import './style.scss'

const packageModalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(70, 70, 70, 0.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    position: 'relative',
    border: 'none',
    background: '#132209',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    padding: '20px',
    width: '96%',
    maxWidth: '400px',
    borderRadius: '18px',
    inset: '0'
  }
}

export default function PackageModal({ isOpen, onRequestClose, selectedPackage, handleChange }) {

  const handleClick = (index) => {
    if(index === selectedPackage) return
    handleChange(index)
    onRequestClose()
  }

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={packageModalStyle}
      ariaHideApp={false}
    >
      <div className='packagelist-modal'>
        <div className='modal-title'>
          Arcade Token Package
          <button className='modal-close-button' onClick={onRequestClose}>
            <img src="./image/close.png" alt="" />
          </button>
        </div>
        <div className='line'></div>
        <div className='list-layout list-header'>
          <div className='image'>Packages</div>
          <div className='amount'>#</div>
          <div className='bonus'>Get Free Token</div>
          <div className='bonus-percentage'>Free %</div>
          <div className='total'>Total</div>
        </div>
        <ul>
          {
            packages.map((val, index) => (
              <li
                className={'list-layout list-item ' + (selectedPackage === index ? 'active' : '')}
                key={index}
                onClick={() => handleClick(index)}
              >
                <div className='image'>
                  <img src={val.image} alt="package" />
                </div>
                <div className='amount'>{val.amount}</div>
                <div className='bonus'>{val.free}</div>
                <div className='bonus-percentage'>+{val.percentage}%</div>
                <div className='total'>{val.total}</div>
              </li>
            ))
          }
        </ul>
      </div>
    </ReactModal>
  )
}