import React, { useState, useEffect } from "react";
import PackageModal from "../../components/PackageModal/PackageModal";
import FiatModal from "../../components/FiatModal/FiatModal";
import { css } from "@emotion/react";
import packages from "../../data/packages.json";
import fiats from "../../data/fiats.json";
import "./style.scss";
import { useEthers } from "@usedapp/core";
import { getValues, setData } from "../../utils/firebase";
import { PulseLoader } from "react-spinners";
import PaypalModal from "../../components/PaypalModal/PaypalModal";
import { CardPay, GooglePay, init, payViaCard } from "../../utils/square";

const spinnerStyle = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function ViaFiat({ handleBack }) {
  const { account } = useEthers();

  const dabaPrice = 1;

  const [isOpenPaypalModal, setIsOpenPaypalModal] = useState(false);
  const [isOpenPackageModal, setIsOpenPackageModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [isOpenFiatModal, setIsOpenFiatModal] = useState(false);
  const [selectedFiat, setSelectedFiat] = useState("paypal");
  const [fireBalance, setFireBalance] = useState({ AT: 0, DB: 0, BOUNTY: 0 });
  const [proceedBtnText, setProceedBtnText] = useState("Proceed");

  const onSuccess = () => {
    let tmp = {
      ...fireBalance,
      AT: fireBalance.AT + packages[selectedPackage].total,
    };
    
    setData(account, tmp);
  }

  const loadBalanceFromFirebase = async () => {
    try {
      let t = await getValues(account);
      setFireBalance(t);
    } catch (error) {
      setFireBalance(undefined);
    }
  };

  const handleProceed = async () => {
    if (fireBalance === undefined) {
      loadBalanceFromFirebase();
      return;
    }
    setProceedLoading(true);
    if (selectedFiat === "paypal") {
      setIsOpenPaypalModal(true);
    } else if (selectedFiat === "google") {
      await GooglePay(packages[selectedPackage].amount * dabaPrice);
      setProceedLoading(false);
    } else if (selectedFiat === "card") {
      await payViaCard(packages[selectedPackage].amount * dabaPrice)
      setProceedLoading(false)
    } else if (selectedFiat === "masterpass") {
    }
  };

  useEffect(() => {
    if (account) {
      setProceedBtnText("Proceed");
    } else {
      setProceedBtnText("Connect Wallet");
    }
  }, [account]); //eslint-disable-line

  useEffect(() => {
    loadBalanceFromFirebase();
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://sandbox.web.squarecdn.com/v1/square.js";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      init();
      CardPay(document.getElementById("card-container"));
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }, []); //eslint-disable-line

  return (
    <div className="via-fiat">
      <div className="page-container">
        <div className="header">
          <span onClick={handleBack}>Buy Arcade</span>
          Via Fiat
        </div>

        <div className="select-container">
          <div className="select-container-body">
            <div className="title">Payment Method</div>
            <button
              className="list-button"
              onClick={() => setIsOpenFiatModal(true)}
            >
              <img src={fiats[selectedFiat].image} alt="payment" />
              {fiats[selectedFiat].title}
              <img src="./image/arrow-down.png" alt="arrow" />
            </button>
            <FiatModal
              isOpen={isOpenFiatModal}
              onRequestClose={() => setIsOpenFiatModal(false)}
              selectedFiat={selectedFiat}
              handleChange={setSelectedFiat}
            />
          </div>
        </div>

        <div className="select-container">
          <div className="select-container-body">
            <div className="value">{packages[selectedPackage].total}</div>
            <button
              className="list-button"
              onClick={() => setIsOpenPackageModal(true)}
            >
              <img src="./image/arcade-token.png" alt="arcade" />
              Packages
              <img src="./image/arrow-down.png" alt="arrow" />
            </button>
            <PackageModal
              isOpen={isOpenPackageModal}
              onRequestClose={() => setIsOpenPackageModal(false)}
              selectedPackage={selectedPackage}
              handleChange={setSelectedPackage}
            />
          </div>

          <div className="select-container-footer">
            <div className="footer-left">
              = $ {packages[selectedPackage].amount * dabaPrice}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: selectedFiat === "card" ? 'block' : 'none',
          marginTop: 30
        }}
      >
        <div id="card-container"></div>
      </div>
      

      <button
        disabled={
          proceedBtnText === "Proceed" && !proceedLoading ? "" : "disabled"
        }
        onClick={handleProceed}
      >
        {proceedLoading ? (
          <PulseLoader
            color="#bbb"
            loading={true}
            size={6}
            css={spinnerStyle}
            margin={7}
          />
        ) : (
          proceedBtnText
        )}
      </button>

      <PaypalModal
        isOpen={isOpenPaypalModal}
        fireBalance={fireBalance}
        account={account}
        onRequestClose={() => {
          setIsOpenPaypalModal(false);
          setProceedLoading(false);
          loadBalanceFromFirebase();
        }}
        onSuccess={onSuccess}
        amount={packages[selectedPackage].amount * dabaPrice}
      />
    </div>
  );
}
