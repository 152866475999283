import { useEthers, shortenAddress } from "@usedapp/core";
import React, { useState } from "react";
import WalletModal from "../../components/WalletModal/WalletModal";
import Web3 from "web3";

import networks from "../../data/networks";
import metamasknets from '../../data/metamasknets'

import "./style.scss";

export default function Header() {
  const [isOpenWalletModal, setIsOpenWalletModal] = useState(false)

  const { account, chainId, library, active, deactivate } = useEthers()

  const handleConnectButton = async () => {
    if(account && active) {
      deactivate()
    }
    else {
      setIsOpenWalletModal(true)
    }
  };

  const changeNetwork = async (newChainId) => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(newChainId) }]
      });
    } catch (switchError) {
      console.log("ErrorCode: ", switchError.code)
      if (switchError.code === 4902 || switchError.code === -32603) {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [metamasknets[newChainId]]
        })
        .catch((error) => {
          console.log(error.code)
        })
      }
    }
  }

  return (
    <header>
      <div className="logo">
        <img src="./image/logo.png" alt="logo" />
      </div>
      <div className="space"></div>
      {
        account && active ? 
          <div className="chain-list">
            <div className="chain-header">
              <img src={networks[chainId].image} alt="network" />
              <div>{networks[chainId].name}</div>
              <img src="./image/arrow-down.png" alt="drop-down" />
            </div>
            <div className="chain-popup">
              <ul>
                {
                  Object.keys(networks).map(key => (
                    <li 
                      className={networks[key].chainIdInt === chainId ? 'chain-button active' : 'chain-button'}
                      key={networks[key].chainIdInt}
                      onClick={() => changeNetwork(networks[key].chainIdInt)}
                    >
                      <img src={networks[key].image} alt={networks[key].name} />
                      <div>{networks[key].name}</div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        : ''
      }
      <button onClick={handleConnectButton}>
        <div>{account && active ? shortenAddress(account) : "Connect"}</div>
        <img src="./image/wallet-icon.png" alt="wallet-icon.png" />
      </button>
      <WalletModal 
        isOpen={isOpenWalletModal}
        onRequestClose={() => setIsOpenWalletModal(false)}
      />
    </header>
  );
}
