import MainLayout from "./layouts/MainLayout/MainLayout";
import MainPage from './pages/MainPage/MainPage';

import { getTokenPrices } from "./utils/utils";
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    getTokenPrices()
    setInterval(getTokenPrices, 180000)
  }, [])

  return (
    <div className="App">
      <MainLayout>
        <MainPage />
      </MainLayout>
    </div>
  );
}

export default App;
