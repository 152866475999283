import React, { useState, useEffect } from "react";
import FiatModal from "../../components/FiatModal/FiatModal";
import { css } from "@emotion/react";
import fiats from "../../data/fiats.json";
import "./style.scss";
import { useEthers } from "@usedapp/core";
import { getValues, setData } from "../../utils/firebase";
import { PulseLoader } from "react-spinners";
import PaypalModal from "../../components/PaypalModal/PaypalModal";
import { CardPay, GooglePay, init, payViaCard } from "../../utils/square";
import { useRef } from "react";

const spinnerStyle = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const bountyCoinPrice = 5;

export default function BuyBountyViaFiat({ handleBack }) {
  const { account } = useEthers();
  
  const valueRef = useRef()
  
  const [isOpenPaypalModal, setIsOpenPaypalModal] = useState(false);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [isOpenFiatModal, setIsOpenFiatModal] = useState(false);
  const [selectedFiat, setSelectedFiat] = useState("paypal");
  const [accountBalance, setAccountBalance] = useState({ AT: 0, DB: 0, BOUNTY: 0 });
  const [proceedBtnText, setProceedBtnText] = useState("Proceed");
  const [bountyBalance, setBountyBalance] = useState(1)

  const handleSuccess = () => {
    let tmp = { ...accountBalance };
    if (tmp.BOUNTY) {
      tmp.BOUNTY += parseInt(bountyBalance);
    } else {
      tmp.BOUNTY = parseInt(bountyBalance);
    }

    setData(account, tmp);
    setAccountBalance(tmp);
    alert.success("Success");
  };

  const loadBalanceFromFirebase = async () => {
    try {
      let t = await getValues(account);
      setAccountBalance(t);
    } catch (error) {
      setAccountBalance(undefined);
    }
  };

  const handleProceed = async () => {
    if (accountBalance === undefined) {
      loadBalanceFromFirebase();
      return;
    }
    setProceedLoading(true);
    if (selectedFiat === "paypal") {
      setIsOpenPaypalModal(true);
    } else if (selectedFiat === "google") {
      await GooglePay(bountyBalance * bountyCoinPrice);
      setProceedLoading(false);
    } else if (selectedFiat === "card") {
      await payViaCard(bountyBalance * bountyCoinPrice);
      setProceedLoading(false);
    } else if (selectedFiat === "masterpass") {
    }
  };

  useEffect(() => {
    if (account) {
      setProceedBtnText("Proceed");
    } else {
      setProceedBtnText("Connect Wallet");
    }
  }, [account]); //eslint-disable-line

  useEffect(() => {
    loadBalanceFromFirebase();
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://sandbox.web.squarecdn.com/v1/square.js";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      init();
      CardPay(document.getElementById("card-container"));
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }, []); //eslint-disable-line

  return (
    <div className="via-fiat">
      <div className="page-container">
        <div className="header">
          <span onClick={handleBack}>Buy Bounty Coin</span>
          Via Fiat
        </div>

        <div className="select-container">
          <div className="select-container-body">
            <div className="title">Payment Method</div>
            <button
              className="list-button"
              onClick={() => setIsOpenFiatModal(true)}
            >
              <img src={fiats[selectedFiat].image} alt="payment" />
              {fiats[selectedFiat].title}
              <img src="./image/arrow-down.png" alt="arrow" />
            </button>
            <FiatModal
              isOpen={isOpenFiatModal}
              onRequestClose={() => setIsOpenFiatModal(false)}
              selectedFiat={selectedFiat}
              handleChange={setSelectedFiat}
            />
          </div>
        </div>

        <div className="select-container">
          <div className="select-container-body">
            <div className="input-container">
              <input
                type="number"
                className="value"
                ref={valueRef}
                onChange={() => setBountyBalance(valueRef.current.value)}
                placeholder="Input Amount"
              />
            </div>
            <button className="list-button" onClick={() => {}}>
              <img src="./image/bounty-coin.png" alt="money coin" />
              BOUNTY
            </button>
          </div>
          <div className="select-container-footer">
            <div className="footer-left">
              = ${bountyBalance * bountyCoinPrice}
            </div>
            <div className="footer-right">
              Balance: {accountBalance.BOUNTY ? accountBalance.BOUNTY : 0}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: selectedFiat === "card" ? "block" : "none",
          marginTop: 30,
        }}
      >
        <div id="card-container"></div>
      </div>

      <button
        disabled={
          proceedBtnText === "Proceed" && !proceedLoading ? "" : "disabled"
        }
        onClick={handleProceed}
      >
        {proceedLoading ? (
          <PulseLoader
            color="#bbb"
            loading={true}
            size={6}
            css={spinnerStyle}
            margin={7}
          />
        ) : (
          proceedBtnText
        )}
      </button>

      <PaypalModal
        isOpen={isOpenPaypalModal}
        fireBalance={accountBalance}
        account={account}
        onRequestClose={() => {
          setIsOpenPaypalModal(false);
          setProceedLoading(false);
          loadBalanceFromFirebase();
        }}
        onSuccess={handleSuccess}
        amount={bountyBalance * bountyCoinPrice}
      />
    </div>
  );
}
