import React, { useRef, useState, useEffect } from 'react'
import { PulseLoader } from "react-spinners";
import { css } from "@emotion/react";

import './style.scss'
import { useEthers } from '@usedapp/core';
import { getValues, setData } from '../../utils/firebase';
import { sendDaba } from '../../utils/daba';
import { useAlert } from 'react-alert';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const spinnerStyle = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const setValuePercentage = [10, 30, 50, 75, 100]

export default function ToFantom({ handleBack }) {

  const valueRef = useRef()

  const { chainId, account, activateBrowserWallet } = useEthers()
  const alert = useAlert()

  const [balance, setBalance] = useState({AT: 0, DB: 0, BOUNTY: 0})
  const [sendLoading, setSendLoading] = useState(false)

  const handleChange = () => {
    if(balance.DB < valueRef.current.value) {
      valueRef.current.value = balance.DB
    }
  }

  const handleSend = async () => {
    setSendLoading(true)
    try {
      let res = await sendDaba(account, valueRef.current.value)
        console.log(res)
      if(res === 'success') {
        let b = {
          ...balance,
          DB: balance.DB - valueRef.current.value
        }

        setBalance(b)
        await setData(account, b)
        alert.success('Success')
      }
      else {
        alert.error('Failed')
      }
    } catch (error) {
      alert.error('Failed')
    }
    setSendLoading(false)
  }

  const loadBalance = async () => {
    let v = await getValues(account)
    setBalance(v)
    if(v.DB > 0)
      valueRef.current.value = '1'
  } 

  useEffect(() => {
    if(account) {
      loadBalance()
    }
    else {
      activateBrowserWallet()
    }
  }, [account]) //eslint-disable-line

  return (
    <div className='to-fantom'>
      <div className='page-container'>
        <div className='header'>
          <span onClick={handleBack}>Trade DABA</span>
          IGD to OCD
        </div>

        <div className='select-container'>
          <div className='select-container-body'>
            <div className='input-container'>
              <input
                type="number"
                className='value'
                ref={valueRef}
                onChange={handleChange}
                placeholder="Input Amount"
              />
            </div>
            <button
              className='list-button'
              onClick={() => {}}
            >
              <img
                src='./image/monkey.png'
                alt='money coin'
              />
              DABA
            </button>
          </div>
          <div className='select-container-footer'>
            <div className='footer-left'></div>
            <div className='footer-right'>
              Balance: {balance.DB}
            </div>
          </div>
        </div>

        <div className='value-setting'>
          { 
            setValuePercentage.map(val => (
              <div
                key={val}
                onClick={() => {valueRef.current.value = balance.DB * val / 100}}
              >
                {val}%
              </div>))
          }
        </div>
      </div>
      <button
        disabled={(chainId !== 250 && chainId !== 4002) || balance.DB === 0 || sendLoading ? 'disabled' : ''}
        onClick={handleSend}
      >
        {
          sendLoading ? 
            <PulseLoader color="#bbb" loading={true} size={6} css={spinnerStyle} margin={7}/>
          : chainId !== 250 && chainId !== 4002 ?
            'Change to Fantom Network'
          : balance.DB === 0 ?
            'Insufficient Balance'
          :
            'S E N D'
        }
      </button>
    </div>
  )
}
