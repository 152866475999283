
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set } from "firebase/database";

import accessTokens from '../data/firebase_access_tokens.json'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "boojabaunga-c5d32.firebaseapp.com",
  databaseURL: "https://boojabaunga-c5d32-default-rtdb.firebaseio.com",
  projectId: "boojabaunga-c5d32",
  storageBucket: "boojabaunga-c5d32.appspot.com",
  messagingSenderId: "1023793265907",
  appId: "1:1023793265907:web:0f9395f06a8519177e0b86",
  measurementId: "G-LLKT9SY5TG"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

export const getValues = (player) => new Promise((resolve) => {
  const starCountRef = ref(db, `${process.env.REACT_APP_FIREBASE_DB_TOKEN}/${player.toLowerCase()}`);
  onValue(starCountRef, (snapshot) => {
    let data = snapshot.val();
    let resData = {}
    if(data === null) {
      resData = {
        AT: 0,
        DB: 0,
        BOUNTY: 0
      }
    }
    else {
      for(let key in data) {
        resData[accessTokens[key]] = data[key]
      }
    }
    return resolve(resData)
  });
})

export const setData = async (player, balance) => {
  let resData = {}
  for(let key in balance) {
    resData[accessTokens[key]] = balance[key]
  }
  await set(ref(db, `${process.env.REACT_APP_FIREBASE_DB_TOKEN}/${player.toLowerCase()}`), resData);
}