import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'

import './style.scss'

export default function MainLayout({children}) {
  return (
    <div className='mainlayout'>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}
